<template>
  <div id="Relatorios">

    <v-toolbar-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="headline lighten-2">
      <!-- TITULO DA PAGINA -->
      <v-toolbar-title class="text-white title-page body-1 mt-1 ml-3 w-100 mb-0">
        <router-link
        :to="{ name: 'Empreendimentos' }"
        class="nav-link"
        aria-current="page"
        >
          <v-btn icon dark color="grey" class="btn-icon mr-1">
            <v-icon color="#84A0CD" class="icon-action"> mdi-chevron-left </v-icon>
          </v-btn>
        </router-link> 
        Relatórios
      </v-toolbar-title>
      <!-- FIM TITULO DA PAGINA -->

    </v-toolbar-title>

    <v-container class="container rounded-lg mt-n10 pa-0">
      <v-card :loading="loading" elevation="0" class="card d-flex flex-column pb-0">
        <v-card-text class="card-content my-0 py-0 px-2">

          <v-row class="wrapper-bottom my-0 py-0 px-1">
            <!-- COLUNA ESQUERDA ------------------------------->
            <v-col class="col-esquerda shrink d-none d-sm-flex flex-column rounded-tl-lg rounded-bl-lg pa-0">
              <div
                v-if="expand"
                class="list rounded-bl-lg overflow-y-scroll pt-0" dense>
                <!-- PESQUISA -------------------->
                <div
                  class="list mt-2 mb-n4 mx-3"
                  dark
                  elevation="0"
                >
                  <v-text-field
                  v-model="search"
                  prepend-inner-icon="mdi-magnify"
                  clear-icon="mdi-close-circle-outline"
                  label="Buscar..."
                  background-color="#FFFFFF"
                  solo
                  dense
                  flat
                  class="search-input"
                  ></v-text-field>
                </div>
                <!-- FIM PESQUISA -------------------->
                
                <v-treeview
                v-model="tree"
                :open="open"
                :items="menu_config"
                :search="search"
                :filter="filter"
                activatable
                return-object
                open-on-click
                open-all
                class="treeview overflow-y-scroll mx-auto rounded-bl-lg"
                @update:active="abreRelatorioExterno2"
                >
                <!--  open-all
                      item-key="name"
                -->
                  <template v-slot:label="{item}">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <div  v-if="!item.children" 
                        class="text-menu mb-1 mt-1"
                        v-bind="attrs"
                        v-on="on">
                          <v-icon class="icon-size">mdi-file-document-outline</v-icon>
                          <span class="text-relatorio text-descricao mt-3 ml-2">{{item.name}}</span>
                          <br>
                          <p class="text-wrap text-descricao ml-7 mb-n1">{{item.descricao}}</p>
                          <div class="mt-2 ml-8 " style="border-bottom: 1px solid rgba(0, 0, 0, 0.08);"></div>
                        </div>

                        <div v-else >
                          <span class="text-relatorio ml-2" style="color: #0548A3">{{item.name}}</span>
                        </div>
                      </template>
                      <span>{{item.name}}</span>
                    </v-tooltip>
                  </template>
                </v-treeview>                    

              </div>

              <v-btn
              class="btn-expanded ma-0 pa-0"
              @click="expand = !expand"
              >
                <v-icon
                v-if="expand == false"
                class="size-icon-expanded ma-0 pa-0">
                  mdi-chevron-right
                </v-icon>

                <v-icon
                v-else 
                class="size-icon-expanded ma-0 pa-0">
                  mdi-chevron-left
                </v-icon>
              </v-btn>   

            </v-col>
            <!-- FIM COLUNA ESQUERDA ------------------------------->

            <v-divider class="divisor-vertical" vertical></v-divider>

            <!-- COLUNA DIREITA ------------------------------->
            <v-col class="col-direita mr-0 ml-0 pt-2 pr-3 pb-2 pl-2">
              <div v-if="tree_node" class="d-flex align-center justify-space-between " tabindex="-1">
                <div class="d-flex align-center"><p class="font-weight-bold body-1 mt-3 ml-2" v-bind:style="{ 'color':COR_SUBTITULO }">{{tree_node.name}}</p></div>
              </div>
              
              <!-- RELATÓRIO ------------------------------------>
              <!-- <iframe id="i_frame" name="i_frame" class="i_frame">
              </iframe> -->
              <!-- FIM RELATÓRIO ------------------------------------>

              <!-- RELATÓRIO ------------------------------------>
              <RelatoriosComponente ref="RelatoriosComponente"
                    v-if="tree_node"
                    :Relatorio="tree_node.conteudo"
              />
              <!-- FIM RELATÓRIO ------------------------------------>
            
            </v-col>
            <!-- FIM COLUNA DIREITA ------------------------------->

          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { COR_PRINCIPAL, COR_SECUNDARIA, COR_SUBTITULO } from "../../services/constantes";
import { AbreRelatorioExterno, MenuRelatorioGet } from "../../services/global";
import { API } from "../../services/API";
import { groupArrayMultKeys } from "../../services/funcoes";
import store_usuario from "../../store/store_usuario"
import { Relatorio } from "../../services/global"
import RelatoriosComponente from './RelatoriosComponente.vue';

export default {
  name: "Relatorios",

  components: {
    RelatoriosComponente
  },

  data() {

    return { 

      AbreRelatorioExterno    : AbreRelatorioExterno,
      MenuRelatorioGet        : MenuRelatorioGet,
      groupArrayMultKeys      : groupArrayMultKeys,
      
      COR_PRINCIPAL     : COR_PRINCIPAL,
      COR_SUBTITULO     : COR_SUBTITULO,
      COR_SECUNDARIA    : COR_SECUNDARIA,

      dialogDuplicar    : false,
      tree              : [],
      tree_node         : null,
      open              : [1, 13],
      search            : null,
      loading           : false,
      //titulo_relatorio  : "",
      //getRelatorio      : null,

      menu_config       : [],

      expand: true,
      tab: null,

      abas_mobile: [
        'Dados Básicos', 'Documentos', 'Privilégios',
      ],

    }
  },

  async mounted() {
      // Busca menu para esta aplicacao
      await this.menu_relatorio(this.$options.name);
    },

    computed: {
      isMobile(){
        return this.$vuetify.breakpoint.name==='xs'
      },

      filter () {
        return (item, search, textKey) => {
          // Este codigo limpa acentos e caracteres especiais "normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '')"
          const name  = item.name.toLowerCase().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
          let descr = '';
          if (item.descricao)
            descr = item.descricao.toLowerCase().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
          const searchText = search.toLowerCase().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
          return  name.indexOf(searchText) > -1 ||
                  descr.indexOf(searchText) > -1;
        }
      },
    },

    methods: {

    async menu_relatorio(p_programa) {
      var la_Res = [];
      la_Res = await this.MenuRelatorioGet(p_programa);
      this.menu_config = la_Res;
      //console.log(this.menu_config);
    },

    // Abrindo Relatorio Externo
    abreRelatorioExterno(node) {
      if (node[0]) {
        if (node[0].id) {
          
          //this.titulo_relatorio = node[0].name;
          // Foi preciso usar um progress bar com contador pois a resposta do relatorio nao e sincrona
          this.loading = true;
          // Neste caso o relatorio esta sendo aberto no frame "i_frame" que eh o padrao de nome da funcao
          this.AbreRelatorioExterno(node[0].id);
          setTimeout(() => {
            this.loading = false;
          }, 2000);
        }
      }
    },

    async abreRelatorioExterno2(node) {
      //this.titulo_relatorio = null;
      //this.getRelatorio = null;
      this.tree_node = null;
      if (node[0]) {
        if (node[0].id) {
          let body = {
            nome: node[0].id,
            tipo: "json_conteudo",
            parametros: [{ Name: "cod_usuario", Value: store_usuario.user.cod_usuario }],
          };
          //this.titulo_relatorio = node[0].name;
          //console.log("node", store_usuario.user.cod_usuario, node)
          let lo_Rel = await Relatorio(body);
          //this.getRelatorio = ls_Rel;
          this.tree_node = {...node[0], conteudo: lo_Rel};
          console.log("this.tree_node", this.tree_node);
        }
      }
    },

  }

};
</script>

<style scoped lang="scss">
.title-page {
  width: 600px !important;
  height: 80px;
  font-family: "Open Sans", sans-serif!important;
  font-size: 17px!important;
}

.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}

.nav-link {
  text-decoration: none;
}

.btn-icon:hover {
  background: rgba(255, 255, 255, 0.1);
}

.btn-icon:hover .icon-action {
  color: #FFF!important;
  opacity: 0.8;
}

.container {
  max-width: 100%;
  height: calc(100vh - 110px)!important;
}

.card {
  height: 100%!important;
  border-radius: 8px;
  background: var(--COR_SECUNDARIA);
  box-shadow: var(--COR_SOMBRA)!important;
}

.card-content, .wrapper-bottom {
  height: 100%!important;
}

.col-esquerda {
  max-width: 300px!important;
  height: 100%;
  position: relative;
  background-color: var(--COR_BACKGROUND);
  // background-color: #FCFCFC !important;
  margin-top: 0px;
  padding-top: 2px!important;
}

.list {
  background-color: transparent;
  min-width: 220px!important;
  margin-top: -2px;
  // color: var(--COR_SUBTITULO);
  color: var(--COR_BACKGROUND);
}

.treeview {
  background-color: transparent;
  min-width: 220px;
  margin-top: -2px;
  color: var(--COR_SUBTITULO);
}

.overflow-y-scroll {
  overflow-y: auto;
}

.divisor {
  height: 100%;
}

.text-descricao {
  font-size: 0.9em;
  color: #A0A0A0;
}

.text-relatorio {
  font-size: 1.0;
  color: #707070;
  font-weight: 500;
}

.size-icon-expanded {
  font-size: 15px;
}

.col-direita {
  background-color: #FFFFFF;
  height: 100%;
  width: 70%;
  padding: 15px !important;
}

.icon-size {
  font-size: 20px;
}

.i_frame {
  width: 100% !important;  
  height: calc(100% - 55px) !important;  
  border: none;
}
</style>